
import { Model, Vue } from 'vue-property-decorator'

import Component from 'vue-class-component'

import User from '@/store/modules/user'
import Recording from '@/store/modules/recording'
import Transcripts from '@/store/modules/transcripts'
import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'
import Store from '@/store/index'

import api from '@/api/main'

import { COLOR_VARIANT, TRANSCRIPTS_AGE } from '@/constants'

import { USER_DETAILS_FORM } from '@/config/userDetailsForm'

import ApiException from '@/exceptions/ApiException'
import Configuration from '@/store/modules/configuration'

// create tyepsafe accessor using getModule to access state of the User and Configuration from the store.
const user = getModule(User, Store)
const recording = getModule(Recording, Store)
const transcripts = getModule(Transcripts)
const translations = getModule(Translations) // Store accessor.
const configuration = getModule(Configuration)

const TITLE_KEY = 'userDetailsTitleText'
const SESSION_INFO_KEY = 'userDetailsSessionText'
const LABELS_KEY = 'userDetailsFormLabelsText'
const NEXT_KEY = 'userDetailsNextText'

const UI_TEXT_TOAST_ERROR_TITLE = 'toastErrorErrorTitle'
const UI_TEXT_TOAST_ERROR_USER_CREATE = 'toastErrorUserCreate'

@Component
export default class UserDetailsForm extends Vue {
  @Model('change', { type: Boolean }) readonly checked!: boolean
  isSubmitingData = false

  ageGroup = null
  gender = null
  regionalAccent = null
  userRegion = null
  nativeLanguage = null
  secondLanguage = null

  nextButtonStateClass = 'mt-2 mb-2 font-size-large next-button'

  // fill the drop down options
  ages = USER_DETAILS_FORM?.AGE_OPTIONS as unknown as Array<string>
  genders = USER_DETAILS_FORM?.GENDER_OPTIONS
  accents = USER_DETAILS_FORM?.ACCENT_OPTIONS
  regions = USER_DETAILS_FORM?.REGION_OPTIONS as []
  languages = USER_DETAILS_FORM?.LANGUAGE_OPTIONS
  secondLanguages = USER_DETAILS_FORM?.SECOND_LANGUAGE_OPTIONS

  yourDetailsText = translations.getTranslations(TITLE_KEY)
  sessionText = translations.getTranslations(SESSION_INFO_KEY)
  formLabelsText = translations.getTranslations(LABELS_KEY)
  nextText = translations.getTranslations(NEXT_KEY)

  ageOptionKidsValue = JSON.parse(JSON.stringify(this.ages[1])).value

  displayRegions = this.regions.length > 0

  multiChoiceCountryCode = configuration.countryCode === 'za'

  mounted () {
    user.updateIsValid(false)
    configuration.load()
  }

  // component method - call when the form is submit by the user.
  async submitUserDetails (event: Event) {
    // update the state with the new user details
    event && event.preventDefault() // prevent page refresh on submit

    this.isSubmitingData = true

    const detailsToSubmit = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      age_group: this.ageGroup ?? '',
      gender: this.gender ?? '',
      accent: this.regionalAccent ?? '',
      region: this.userRegion ?? '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      native_language: this.nativeLanguage ?? '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      second_language: this.secondLanguage ?? ''
    }

    try {
      const response: Record<string, string> = await api.createUser(detailsToSubmit)
      transcripts.setTranscriptionType(this.ageGroup === this.ageOptionKidsValue ? TRANSCRIPTS_AGE.KIDS : TRANSCRIPTS_AGE.DEFAULT)
      user.setUserId(response.user_id)
      user.updateIsValid(true)
      user.updateAgeGroup(this.ageGroup ?? '')

      recording.setRecordingId()

      this.routeToNextPage()
    } catch (apiError) {
      let toastMessageKey: string = UI_TEXT_TOAST_ERROR_USER_CREATE
      let toastColorVariant: string = COLOR_VARIANT.DANGER

      if (apiError instanceof ApiException) {
        toastMessageKey = apiError.toastMessage
        toastColorVariant = apiError.toastColor
      }

      this.$bvToast.toast(translations.getTranslations(toastMessageKey), {
        title: translations.getTranslations(UI_TEXT_TOAST_ERROR_TITLE),
        variant: toastColorVariant,
        solid: true
      })
    }
  }

  // component method to route to the next page.
  routeToNextPage () {
    this.$router.push('/audio-check')
  }
}
